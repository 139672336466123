document.addEventListener("DOMContentLoaded", function() {
  var lazyBackgrounds = [].slice.call(document.querySelectorAll(".lazy-bg"));

  if ("IntersectionObserver" in window) {
    let lazyBackgroundObserver = new IntersectionObserver(function(entries, observer) {
      entries.forEach(function(entry) {
        if (entry.isIntersecting) {
          let lazyBackground = entry.target;
          let lazyUrl = lazyBackground.dataset.bg;
          if (lazyUrl.includes("https://jfaprod300924.s3.amazonaws.com/media/https://jfaprod300924.s3.amazonaws.com/media/")) {
            lazyUrl = lazyUrl.replace("https://jfaprod300924.s3.amazonaws.com/media/https://jfaprod300924.s3.amazonaws.com/media/", "https://jfaprod300924.s3.amazonaws.com/media/");
          }
          lazyBackground.style.backgroundImage = 'url(' + lazyUrl + ')';
          lazyBackground.classList.remove("lazy-bg");
          lazyBackgroundObserver.unobserve(lazyBackground);
        }
      });
    });

    lazyBackgrounds.forEach(function(lazyBackground) {
      lazyBackgroundObserver.observe(lazyBackground);
    });
  } else {
    // Possibly fall back to event handlers here
  }
});
